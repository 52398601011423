.form-group {
  margin-bottom: 2rem !important;
}

.form-control,
.select-custom {
  margin-bottom: 0 !important;
}

.form-control {
  height: 40px !important;
  font-size: 1.4rem !important;
  line-height: 1.5 !important;
  font-weight: 300 !important;
  color: #777 !important;
  background-color: #fafafa !important;
  border: 1px solid #ebebeb !important;
  border-radius: 0 !important;
  margin-bottom: 2rem !important;
  transition: all 0.3s !important;
  box-shadow: none !important;
  width: 100% !important;

  &:disabled,
  &[readonly] {
    background-color: #e9ecef !important;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}
